/*
* @organization: neopolis-dev
* @project: neopolisGPT
* @platform: PC (DESKTOP)
* @created: 2022-09-17
* @updated: 2022-09-18
* @framework: React
* @author: taher
* @version: 0.0.1
*/

div.active-guest-messages {
  scroll-behavior: smooth;
  padding-right: 15px;
  padding-left: 20px;
  overflow-y: auto;
  position: fixed;
  bottom: 58px;
  top: 0px;
  right: 0;
  left: 0;
  padding-bottom: 50px;
}

div.active-guest-messages::-webkit-scrollbar {
  width: 10px;
}

div.active-guest-messages::-webkit-scrollbar-track {
  background-color: #fff;
}

div.active-guest-messages::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 10px;
}

div.active-guest-messages div.msg-date label {
  font-family: 'RedHatDisplayRegular';
  color: #b4b4b4;
  font-weight: bold;
  font-size: 11px;
}
